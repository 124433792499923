.bttn {
    position: relative;
    padding: 0 10px;
    margin: 0;
    /* background: #FCCC8A; */
    font-size: 1.55rem;
    /* font-stretch: expanded; */
    font-weight: 700;

    color: white;
    /* text-shadow: -1.5px -1.5px 0 black, 1.5px -1.5px 0 black, -1.5px 1.5px 0 black, 1.5px 1.5px 0 black; */
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    top: -2px;
    margin: 0 8px ; 
    border-radius: 10px;
    cursor: pointer; /* Cursor on hover */
    
}

.bttn-red {
    background: #CB291D;
    box-shadow: 2px 7px 5px #473423, 1px 3px 8px #FA345C inset;
}

.bttn-red:hover {
    background: #A3211F;
    box-shadow: 0 2px 5px #473423 inset, -1px -3px 4px #FA345C inset;
}

.bttn-green {
    background: #7BBE39;
    box-shadow: 2px 7px 5px #473423, 1px 3px 8px #A5FC4E inset ; 
}
    
.bttn-green:hover {
    background: #4e7924;
    box-shadow: 0 2px 5px #3d5e1c inset, -1px -3px 4px #7BBE39 inset;
}

.bttn-tan {
    background: #FCCC8A;
    /* border: 2px solid #473423; */
    box-shadow: 2px 7px 5px #473423, 1px 3px 8px #EAD5C0 inset ; 
}

.bttn-tan:hover {
    background: #CC9A58;
    box-shadow: 0 2px 5px #473423 inset, -1px -3px 4px #F2C18A inset;

}

.bttn-blue {
    background: #375092;
    border: 1px solid #BBC8CE;
    box-shadow: 2px 7px 5px #3B3B3B, 1px 3px 8px #3B3B3B inset
}

.bttn-blue:hover {
    background: #283265;
    box-shadow: 0 2px 5px #375092 inset, -1px -3px 4px #3B3B3B inset;
}

.bttn-gray {
    background: #C6C3C6;
    box-shadow: 2px 7px 5px #393839, 1px 3px 8px #7B796B inset  
}

.bttn-gray:hover {
    background: #9C9A9C;
    box-shadow: 0 2px 5px #393C39 inset, -1px -3px 4px #E7E3E7 inset;
}

.bttn-yellow {
    background: #FFBA32;
    box-shadow: 2px 7px 5px #473423, 1px 3px 8px #FFECB7 inset ; 
}
    
.bttn-yellow:hover {
    background: #CD8508;
    box-shadow: 0 2px 5px #A55D01 inset, -1px -3px 4px #FFBA32 inset;
}